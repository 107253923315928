<template>
    <b-container fluid>
        <!-- {{ week.range }} -->
        
        <vue-context ref="rightClickMenu" v-slot="{ data }" :closeOnScroll="true">
            <li class="text-center">
                <button class="btn btn-danger btn-sm" @click="deleteWorkerFromDay(data)">Löschen</button>
            </li>
        </vue-context>
        <div class="row" v-if="workers.length > 0">
            <div class="col-md-10 overflow-auto" style="height: 38rem;border: 1px solid #dee2e6;">
                <div>
                    <table class="table table-head-fixed table-row-fixed text-nowrap">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-center" v-for="(day) in week.range" :key="day.format('D.M.Y') + '-' +Math.random() * 10000">
                                    {{ day.format('dddd') }} {{ day.format('DD.MMM') }}
                                </th>
                                <th>
                                    Stunden Filiale
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(store, storeIndex) in stores" :key="store.id + '_' + storeIndex">
                                <th>{{ store.name }}</th>
                                <td v-for="(day, dayIndex) in week.range" :key="day.format('D.M.Y') + '-' + dayIndex">
                                    <div class="card card-success"  style="width: 15rem">
                                        <div class="card-header p-2">
                                            <div class="card-title">
                                                <div :class="getBusinessTimes(store, day.format('Y-MM-DD')).style" class="mx-auto">
                                                    {{ getBusinessTimes(store, day.format('Y-MM-DD')).text }}
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table">
                                            <draggable tag="tbody" :group="{ name: 'people', pull: pullFunction}" @start="start" :clone="function(original) { return {...original} }" class="card-body" :list="workers[dayIndex][storeIndex]" :scroll-sensitivity="200" :component-data="getComponentData()" easing="cubic-bezier(1, 0, 0, 1)"  animation="200" ghost-class="ghost">
                                                <template v-if="workers[dayIndex][storeIndex].length > 0">
                                                    <tr  v-for="(worker, workerIndex) in workers[dayIndex][storeIndex]" :key="worker.id + '_' + storeIndex" :class="isDublicated(worker.employee.id, dayIndex) + ' ' + getAbsend(day, worker.employee).class"  @contextmenu.prevent="openMenu($event,dayIndex,storeIndex, workerIndex, worker.employee)">
                                                        <td colspan="2"  >
                                                            <b>
                                                                <template v-if="getAbsend(day, worker.employee).message != null">
                                                                    {{ getAbsend(day, worker.employee).message }}
                                                                </template>
                                                                {{ worker.employee.first_name }} {{ worker.employee.last_name }} 
                                                                <br>
                                                            </b>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <input type="time" v-model="workers[dayIndex][storeIndex][workerIndex].start_time" class="form-control form-control-sm">
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input type="time" v-model="workers[dayIndex][storeIndex][workerIndex].stop_time" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-else>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </template>
                                            </draggable>
                                        </table>
                                    </div>
                                </td>
                                <td class="text-center text-bold">
                                    {{ calculateStoreHours(storeIndex) }} Stunden
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td v-for="(day, dayIndex) in week.range" :key="day.format('Y-M-D') + '_' + Math.random()*10" class="text-center text-bold">
                                    {{ calculateDayHours(dayIndex) }} Stunden
                                </td>
                                <td class="text-center text-bold text-primary">
                                    {{ calculateHoursComplete() }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-md-2 overflow-auto" style="height: 38rem;padding:0;" >
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th style="width: 50%">
                                Mitarbeiter
                            </th>
                            <th style="width: 16.6%">
                                Std. <br>
                                Soll
                            </th>
                            <th style="width: 16.6%">
                                Std. <br>
                                Woche
                            </th>
                            <th style="width: 16.6%">
                                Std. <br>
                                Monat
                            </th>
                        </tr>
                    </thead>
                </table>
                <draggable :list="employeeGroups" group="employeeGroups" @change="changeEmployeeGroupOrder()">
                    <div v-for="group in employeeGroups" :key="group.id + '_' + Math.random()" class="pt-2">
                        <b-button v-b-toggle="'collapse-' + group.id" block variant="light">{{ group.name }}</b-button>
                        <b-collapse :id="'collapse-' +  group.id" visible >
                            <table class="table table-sm" >
                                <draggable :sort="false" v-model="group.employees" :group="{name: 'people', put: false, pull: 'clone'}" :clone="function(original) { return {...original} }" tag="tbody" animation="200" ghost-class="ghost">
                                    <tr v-for="worker in group.employees" :key="worker.id">
                                        <td style="width: 50%">{{ worker.employee.first_name }} {{ worker.employee.last_name }}</td>
                                        <td style="width: 16.6%">{{ Math.round((parseFloat(worker.employee.base_time) / 4.33)) }}</td>
                                        <td style="width: 16.6%">{{ calculateWorkerHours(worker.employee.id) }}</td>
                                        <td style="width: 16.6%">{{ calculateMonthHours(worker.employee) }}</td>
                                    </tr>
                                </draggable>
                            </table>
                        </b-collapse>
                    </div>
                </draggable>
            </div>
        </div>
        <div class="text-right">
            <button class="btn btn-danger btn-sm mt-3 mb-0 mr-1" @click="save({active: 1})" :disabled="week.active == 1">
                Freigeben
            </button>
            <button class="btn btn-primary btn-sm mt-3 mb-0" @click="save">
                Speichern
            </button>
        </div>
    </b-container>
</template>

<script>

import draggable from "vuedraggable";
import VueContext from 'vue-context';
import moment from 'moment';
export default {
    name: 'WorkplanWeekView',
    props: ['week', 'employeeGroups', 'stores'],
    components: {draggable, VueContext},
    data() {
        return {
            hasChanges: false,
            workers: [],
            options: [
                {
                    name: 'Löschen',
                }
            ]
        }
    },
    methods: {
        getComponentData() {
            return {
                on: {
                    drop: this.dropHandle,
                }
            };
        },
        dropHandle() {
            this.$emit('has_changes');
        },
        openMenu(event, dayIndex, storeIndex, workerIndex, worker) {
            this.$refs.rightClickMenu.open(event, {dayIndex: dayIndex, storeIndex: storeIndex,workerIndex: workerIndex, worker: worker});
        },
        save(active) {
            let workers = [];
            this.week.range.forEach((day, dayIndex) => {
                this.stores.forEach((store, storeIndex) => {
                    this.workers[dayIndex][storeIndex].forEach((worker, workerIndex) => {
                        workers.push({
                            id: worker.id,
                            store_id: store.id,
                            date: day.format('Y-M-D'),
                            employee_id: worker.employee.id,
                            start_time: worker.start_time,
                            stop_time: worker.stop_time,
                            position: workerIndex,
                        });
                    })
                })
            })
            this.axios
                .put("/workplan-weeks/update/" + this.week.id, {
                    ...active,
                    workplan: workers
                })
                .then(() => {
                    this.$emit('has_changes', false);
                    this.$emit('get_weeks', this.week.id);
                    this.$swal({
                        icon: 'success',
                        position: 'top-end',
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        showConfirmButton: false,
                        title: "Die Woche wurde gespeichert"
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim Speichern ist etwas schief gelaufen'
                    })
                })
        },
        getWorkersOnDay(date, store_id)
        {
            let workers = [];
            this.workers.forEach((element, index) => {
                if(element.workday == date && element.store_id == store_id)
                {
                    element.workers_index = index;
                    workers.push(element);
                }
            })
            return workers;
        },
        isDublicated(workerId, dayIndex) {
            let count = 0;
            let style = null;
            this.workers[dayIndex].forEach(day => {
                day.forEach(worker => {
                    if(workerId == worker.employee.id)
                    {
                        count++;
                    }
                })
            })
            if(count > 1) style = 'table-danger';

            return style;
        },
        pullFunction() {
            return this.controlOnStart ? "clone" : true;
        },
        start({ originalEvent }) {
            this.controlOnStart = originalEvent.ctrlKey;
        },
        timeToDecimal(t) {
            var arr = t.split(':');
            var dec = parseInt((arr[1]/6)*10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
        },
        calculateWorkerHours(workerId) {
            let hours = 0;
            this.workers.forEach((day) => {
                day.forEach(store => {
                    store.forEach(worker => {
                        if(worker.employee.id == workerId)
                        {
                            hours += this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time);
                            if(this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time) > 6)
                            {
                                hours -= 0.5;
                            }
                        }
                    })
                })
            })
            return Math.round(hours * 100) / 100;
        },
        calculateDayHours(dayIndex) {
            let hours = 0;
            this.workers[dayIndex].forEach((store) => {
                store.forEach((worker) => {
                    hours += this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time);
                })
            })
            return Math.round(hours * 100) / 100;
        },
        calculateStoreHours(storeIndex) {
            let hours = 0;
            this.workers.forEach(day => {
                day[storeIndex].forEach(worker => {
                    hours += this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time);
                })
            })
            return Math.round(hours * 100) / 100;
        },
        deleteWorkerFromDay(data) {
            this.$swal({
                title: "Möchtest du " + data.worker.first_name + " " + data.worker.last_name + " löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen.`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.workers[data.dayIndex][data.storeIndex].splice(data.workerIndex, 1);
                    this.$emit('has_changes');
                    this.$swal({
                        icon: 'success',
                        position: 'top-end',
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        showConfirmButton: false,
                        title: "Der Mitarbeiter wurde aus der Planung entfernt"
                    });
                }
            });
        },
        calculateMonthHours(employee) {
            var date = moment().week(this.week.week).year(this.week.year);
            if(employee.salaries != undefined)
            {
                var salarie = employee.salaries.find(element => (element.year == date.format('Y') && element.month == date.format('M')));
                if(salarie != undefined)
                {
                    let hours = 0;
                    salarie.times.forEach(element => {
                        if(element.end_time != null && element.start_time != null) hours += this.timeToDecimal(element.end_time) - this.timeToDecimal(element.start_time);
                        if(element.second_end_time != null && element.second_start_time != null) hours += this.timeToDecimal(element.second_end_time) - this.timeToDecimal(element.second_start_time);
                    })
                    return Math.round(hours * 100) / 100;
                }
                return 0;
            }
            return 0;
        },
        calculateHoursComplete() {
            let hours = 0;
            this.stores.forEach((store, storeIndex) => {
                hours += this.calculateStoreHours(storeIndex);
            })

            return hours;
        },
        getBusinessTimes(store, date) {
            if(store.id == 0)
            {
                return {
                    text: "",
                    style: ''
                }
            }
            let hours = null;
            if(store.open_sundays != null && store.open_sundays != undefined)
            {
                store.open_sundays.forEach(element => {
                    if(element.date == date)
                    {
                        hours = {start: element.start_time, stop: element.stop_time};
                    }
                })
            }
            if(hours != null)
            {
                if(hours.start == '00:00:00' && hours.stop == '00:00:00')
                {
                    return {
                        text: "Geschlossen",
                        style: 'text-danger'
                    }
                } else {
                    return {
                        text: moment('2023-03-03 ' + hours.start).format('HH:mm') + ' bis ' + moment('2023-03-03 ' + hours.stop).format('HH:mm') + " Uhr",
                        style: 'text-danger'
                    }
                }
            }
            if(moment(date).format('dddd') == 'Sunday')
            {
                return {
                    text: "Geschlossen",
                    style: 'text-danger'
                }
            }

            if(store.business_hours != null && store.business_hours != undefined)
            {
                store.business_hours.forEach(element => {
                    if(moment(date).isoWeekday()-1 == element.day_of_week)
                    {
                        hours = {
                            start: element.open_time,
                            stop: element.close_time
                        }
                    }
                })

                if(hours != null)
                {
                    return {
                        text: moment('2023-03-03 ' + hours.start).format('HH:mm') + ' bis ' + moment('2023-03-03 ' + hours.stop).format('HH:mm') + " Uhr",
                        style: ""
                    }
                }
            }
            return {
                text: "",
                style: "",
            }
        },
        getAbsend(date, employee)
        {
            let absend = {
                message: null,
                class: null,
            };
            employee.holidays.forEach(element => {
                let date1 = moment(this.parseDate(element.start_date));
                let date2 = moment(this.parseDate(element.end_date));
                if(date.isBetween(date1, date2, 'days', []))
                {
                    if(element.state.name == 'absend')
                    {
                        absend.message = 'A'
                        absend.class = "table-active";
                    } else if(element.state.name == 'absend_morning')
                    {
                        absend.message = 'AV'
                        absend.class = "table-active";
                    } else if(element.state.name == 'absend_afternoon')
                    {
                        absend.message = 'AN'
                        absend.class = "table-active";
                    } else if(element.state.name == 'confirmed' || element.state.name == 'confirmed_unpaid')
                    {
                        absend.message = 'U'
                        absend.class = "bg-lightyellow";
                    } else if(element.state.name == 'ill' || element.state.name == 'ill_unpaid')
                    {
                        absend.message = 'K'
                        absend.class = "bg-lavendel";
                    }
                }
            });
            if(absend.message != null)
            {
                absend.message += " - ";
            }
            return absend;
        },
        parseDate(date)
        {
            let element = date.split(/[\s-:]+/);
            return {
                year: parseInt(element[0]),
                month: parseInt(element[1])-1,
                date: parseInt(element[2]),
            }
        },
        changeEmployeeGroupOrder()
        {
            this.axios
                .post('/employee-group/change-workplan-order', {
                    employeeGroups: this.employeeGroups
                })
                .then(() => {
                    this.$swal({
                        icon: 'success',
                        position: 'top-end',
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        showConfirmButton: false,
                        title: "Reihenfolge erfolgreich geändert"
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim Ändern der Reihenfolge ist etwas schiefgelaufen'
                    })
                })
        }
    },
    mounted() {
        this.week.range.forEach((day, dayIndex) => {
            this.workers.push([]);
            this.stores.forEach((store, storeIndex) => {
                this.workers[dayIndex].push([]);
                this.week.workers.forEach(worker => {
                    if(worker.store_id == store.id && worker.date == day.format('Y-MM-DD'))
                    {
                        this.workers[dayIndex][storeIndex].push({
                            employee: worker.employee,
                            id: worker.id,
                            store_id: worker.store_id,
                            workday: worker.date,
                            start_time: worker.start_time,
                            stop_time: worker.stop_time,
                        })
                    }
                })
            });
        })
    }
}
</script>

<style>
.table.table-head-fixed thead tr:nth-child(1) th {
    top: -.45rem !important;
}
.table.table-row-fixed tbody tr th:nth-child(1)
{
    background-color: #fff;
    /* border-bottom: 0; */
    /* box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6; */
    position: -webkit-sticky;
    position: sticky;
    left: -.45rem;
    z-index: 10;
}
@import '~vue-context/dist/css/vue-context.css';
.bg-lavendel {
    background-color: #c78fff;
}
.bg-lightyellow {
    background-color: rgb(255, 251, 199);
}
</style>